import {insecureMode, WebClient} from "../../utils/utils";
let axios = new WebClient();
const log = require('debug')("LoginModule");


export const getProfile = async function() {
  let data = {authenticated:false,type:"",profileSet:false}
  if (insecureMode()) {
    log("Overriding user login")
    data.authenticated=true;
    data.type="I";
    data.profileSet=!!localStorage.getItem("GSDB");
  } else {
    let res = await axios.get("/auth/profile");
    if(res && res.data) {
      data=res.data;
    }
  }
  if(!data.authenticated) {
    log("Redirecting to ",process.env.REACT_APP_GATEWAY_URL + "/auth/login?homePage=" + window.location.href);
    window.location.assign(process.env.REACT_APP_GATEWAY_URL + "/auth/login?homePage=" + window.location.href)
  }
  return data;
}

export const updateURL = function(url : string) {
  if(insecureMode() && localStorage.getItem("GSDB")) {
    if(localStorage.getItem("GSDB")) {
      if(url.indexOf("?") > 0) {
        url += "&"
      } else {
        url += "?"
      }
      url += "gsdb=" + localStorage.getItem("GSDB");
    }
  }
  return url;
}

export const setCurrentSupplier = async function(code : string|null) {
  if (code) {
    localStorage.setItem("GSDB", code);
  } else {
    localStorage.removeItem("GSDB");
  }
  if(insecureMode()) {
    return Promise.resolve();
  } else {
    return axios.put(`/auth/emulate?sitecode=${code}`);
  }
}