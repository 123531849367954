import Debug from "debug";

interface Loggers {
    log: Debug.Debugger,
    error: Debug.Debugger,
    info: Debug.Debugger,
    warn: Debug.Debugger;
}

export default function getLogs(namespace: string): Loggers {
    let log = require('debug')(namespace);
    let info = log.extend("info");
    info.enabled = true;
    info.log = console.info.bind(console);
    let error = log.extend("error");
    error.enabled = true;
    error.log = console.error.bind(console);
    const warn= log.extend("warn");
    warn.enabled=true
    warn.log = console.warn.bind(console);
    return {log: log, error: error, info: info,warn:warn}
}